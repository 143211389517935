import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Layout from "../../components/layout"
import Head from "../../components/head"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
}))

const FamilyLaw = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="Family Law" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Family Law
            </Typography>
            <Typography component="p" paragraph>
              Our office can help you with:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">Divorce applications</Typography>
              <Typography component="li">
                Consent orders for financial agreements
              </Typography>
              <Typography component="li">
                Consent orders for parenting agreements
              </Typography>
              <Typography component="li">Pre-nuptial agreements</Typography>
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              1. Divorce applications
            </Typography>
            <Typography component="p" paragraph>
              For divorce applications you will need to prove:
            </Typography>

            <Typography component="ul" paragraph>
              <Typography component="li">
                You are married to your partner – you need to provide your
                marriage certificate
              </Typography>
              <Typography component="li">
                You are an Australian permanent resident/citizen – provide your
                passport details or Australian citizenship details
              </Typography>
              <Typography component="li">
                You have been separated from your partner for 12 months –
                separate addresses on your driver licences would assist
              </Typography>
            </Typography>

            <Typography component="p" paragraph>
              Where both parties are separated but still live in the same home,
              they would need to provide evidence that they have lived
              completely separate lives for the 12 months prior to the divorce
              application.
            </Typography>
            <Typography component="p" paragraph>
              Divorce applications can be jointly signed by both parties and
              either party would not need to appear in Court except whether
              there is a child under the age of 18 years in the marriage and
              then one party has to appear in Court to confirm that the child is
              being well cared for. The Court would need to be convinced that
              the welfare of the child is properly taken care of before a
              divorce can be granted.
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              2. Financial agreements:
            </Typography>

            <Typography component="p" paragraph>
              Financial agreements relate to the distribution of assets in a
              relationship. They are generally final settlements. Matters that
              are generally taken into the account include: the length of the
              relationship, financial and personal contribution of each party to
              their marriage, and which parent would provide the major future
              care for children in the relationship.
            </Typography>
            <Typography component="p" paragraph>
              The contribution of parties in home duties and care of the
              children have the same weight as a working spouse, and perhaps
              sometimes higher where the person staying at home may have
              sacrificed their own career and financial opportunities to
              maintain a family life for the couple.
            </Typography>
            <Typography component="p" paragraph>
              Consideration should also be given to the future financial
              situation of each party. Where a spouse would have difficulty
              finding work due to stopping their career opportunities to care
              for children, or where a spouse elects to care for the children
              long term, then there is a greater need for financial agreements
              to provide for the more financially dependent spouse.
              Consideration by the “poorer” spouse should also be given to
              claims for spouse maintenance as a supplementary to financial
              agreements.
            </Typography>

            <Typography component="p" paragraph>
              Financial agreements are also required to take into account the
              superannuation accounts of both parties.
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              3. Parenting agreements:
            </Typography>
            <Typography component="p" paragraph>
              Parenting agreements can be oral or in writing. They can be varied
              to take into account changing circumstances of each parent and
              also the children. Both parents have shared responsibility for the
              care and support of their children.
            </Typography>
            <Typography component="p" paragraph>
              All parenting agreements need to be in the best interests of the
              child. What is in the best interests of the child is often
              determined by the parents. However, it is also relevant to
              consider the voice of the child as to whether the child wishes to
              say what they would like in the parenting agreement. While there
              may be concerns as to the child’s maturity to determine their own
              future, the child’s opinion should be taken into account by each
              parent in considering the future care of the child, and also the
              child should be given feedback as to the parents’ final decisions.{" "}
            </Typography>
            <Typography component="p" paragraph>
              As a rule, parents should not denigrate each other in front of
              their children and should be neutral and supportive of their
              child’s wishes to develop positive relationships with both
              parents. Also, parents should not use children as part of their
              bargaining tools to encourage children to say exactly what the
              parent looking after them wants them to say. Children should not
              feel that they are part of the conflict between parents. Rather,
              they should feel that their parents are doing all that they can to
              make sure that the children are well cared for and loved by both
              parents.{" "}
            </Typography>
            <Typography component="p" paragraph>
              Parenting agreements are by nature, future oriented and should
              take into account the parents’ future interests as well as the
              children’s future interests. The issues associated with a parent’s
              relocation to another City or State, needs to be taken into
              account in the level of contact the child has with each parent.
            </Typography>
            <Typography component="p" paragraph>
              Child support: Both parents can determine the level of child
              support payments in a parenting plan. However, where a parent has
              to apply for a government benefit, it is likely that Centrelink
              will require a level of financial support equal to or higher than
              that provided under the Child Support Act.
            </Typography>
            <Typography component="p" paragraph></Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              4. When cases should be referred to the Court – currently the
              Federal Circuit Court
            </Typography>
            <Typography component="p" paragraph>
              The Court is recommended as a last resort where parties are not
              able to negotiate their own settlements. A Court case is also
              recommended where:
            </Typography>

            <Typography component="ul" paragraph>
              <Typography component="li">
                the cases are highly complex and parties cannot reach an
                agreement;
              </Typography>
              <Typography component="li">
                where there is unequal bargaining power between the parties;
              </Typography>
              <Typography component="li">
                where one parent may feel intimidated by the other dominating
                parent and may agree to a parenting plan that is not appropriate
                for the care of their child;
              </Typography>
              <Typography component="li">
                where there are high conflict families;
              </Typography>
              <Typography component="li">
                where there is possibility of abuse or violence against either
                of the parties or their children and;
              </Typography>
              <Typography component="li">
                where a parent needs to have an airport alert to prevent a
                parent taking children out of Australia without consent from the
                other parent.
              </Typography>
            </Typography>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default FamilyLaw
